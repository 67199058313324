import {
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonLoading,
  useIonToast,
  useIonAlert,
  useIonModal,
} from '@ionic/react';
import {useState} from 'react';
import MWRatingFull from '../../../Components/MWRating/MWRatingFull';
import {addToCart, subscriptionsAdd} from '../../Cart/CartStore';
import {isPlatform} from '@ionic/core';
import {reload, checkmark} from 'ionicons/icons';
import MWButton from '../../../Components/MWButton/MWButton';
import {ProductByBarProps, VariantType} from '../types';
import {useStoreState} from 'pullstate';
import {UserStore} from '../../User/UserStore';
import dayjs from 'dayjs';
import CartModal from '../../Cart/CartModal';
import SubscriptionModal from '../../Subscriptions/SubscriptionsModal';

const ProductBuyBar = ({className, ...props}: ProductByBarProps) => {
  const [presentAlert] = useIonAlert();
  let totalInventory = 0;
  const product = props.product;

  product.variants.forEach((variant) => {
    totalInventory += variant.inventory;
  });

  const [currentVariantIndex, setCurrentVariantIndex] = useState(0);
  const [presentToast, dismissToast] = useIonToast();
  const [showLoading, dismissLoading] = useIonLoading();
  const selectedVariant: VariantType | null =
    product?.variants[currentVariantIndex];
  const basePrice: number = product?.variants[currentVariantIndex]?.salePrice
    ? product.variants[currentVariantIndex].salePrice
    : product.variants[currentVariantIndex].price;
  const userState = useStoreState(UserStore);
  const [presentCartModal, dismissCartModal] = useIonModal(CartModal, {
    onDismiss: () => {
      dismissCartModal();
    }
  });
  const [presentSubscriptionModal, dismissSubscriptionModal] = useIonModal(SubscriptionModal, {
    onDismiss: () => {
      dismissSubscriptionModal();
    }
  });

  /**
   * Subscribe to a Variant
   */
  const subscribe = async () => {
    await subscriptionsAdd(product.id, selectedVariant.id, 1);
    presentCartModal();

    product.variants.forEach((variant) => {
      if (variant.id === selectedVariant.id) {
        variant.isSubscribed = true;
      }
    });
  };

  /**
   * Add this to the Cart
   */
  const localAddToCart = async () => {
    if (product && product.id) {
      if ([13369, 13370, 13371, 13368, 13368, 7209].includes(product.id)) {
        presentAlert(
          'April Fools! Just having some fun today with wacky products. Share this product with your friends and see if you can convince them to buy it, too.',
          [
            {
              text: 'Close',
            },
          ]
        ).then();
      } else {
        showLoading('Adding to your cart...').then();
        await addToCart({
          productId: product.id,
          qty: 1,
          variantId: selectedVariant?.id,
        });
        window.gtag('event', 'add_to_cart', {
          id: product.id,
          quantity: 1,
        });
        dismissLoading().then();

        const buttons = isPlatform('mobile')
          ? [
            {
              text: 'View Cart',
              handler() {
                presentCartModal();
                dismissToast();
              },
            },
          ]
          : [];
        presentToast({
          keyboardClose: true,
          position: 'top',
          duration: 1700,
          cssClass: 'mw-toast',
          animated: true,
          buttons: buttons,
          message: `Added ${product.title} to Cart `,
        }).then();
      }
    }
  };

  /**
   * Returns an MWButton that's either View Details or Add to Cart.
   *
   * The default return is the MWButton that adds the product to the users cart. The body of this function should be used to define the cases where a different button should be returned.
   *
   * @returns An MWButton that is either View Details or Add to Cart (default)
   */
  const handleAddOrViewButton = () => {
    // If the product isn't in the users market and there is no inventory return null so we don't render a component.
    // A different piece of logic that is called after this function handles the sold out/watching/etc state
    if (!product.inMarket && totalInventory <= 0) {
      return null;
    }

    // If no other case above is met, return the add to cart button
    return <>
      <MWButton
        title='Add to Cart'
        onClick={localAddToCart}
        bg-gray-200
        className='justify-center w-full text-white bg-green-600 rounded-lg h-11 m-0'
      >
        Add to Cart
      </MWButton>
    </>
  }

  return (
    <>
      <div className={`ProductBuyBar relative ${className}`}>
        <div
          className={`flex flex-row items-center pb-1 space-x-2 divide-gray-200 flex-stiff ${
            props.priceClass || ''
          }`}
        >
          <div aria-label='Product Price' className='font-semibold flex-stiff'>
            <span className='text-red-800 '>
              ${parseFloat(`${basePrice}`).toFixed(2)}
            </span>
          </div>

          {product.variants.length &&
            product.variants[currentVariantIndex].salePrice && (
              <IonText className='line-through opacity-50 flex-stiff'>
                $
                {parseFloat(
                  `${product.variants[currentVariantIndex].price}`
                ).toFixed(2)}
              </IonText>
            )}

          {selectedVariant.inventory === 0 && (
            <div className='flex-fill'>
              <IonText className='text-sm text-red-500 font-black'>
                SOLD OUT
              </IonText>
            </div>
          )}

          {(product.ratingCount >= 3 || (product.ratingCount > 0 && product.ratingCount <= 2 && product.avgRating >= 3.5)) && (
            <MWRatingFull hideValue={true} rating={product.avgRating}/>
          )}
        </div>

        {product?.isPreOrder && selectedVariant && selectedVariant.preOrderDate &&
          <div className='p-2 pt-0'>
            <p className='font-medium text-sm text-black'>
              Arrives: <span
              className='text-red-500'>{dayjs(selectedVariant.preOrderDate.split('T')[0]).format('MM/DD/YYYY')}</span>

              {product.variants.length > 1 &&
                <span>&nbsp; (with other options)</span>
              }
            </p>
          </div>
        }

        <div
          className={`flex flex-col space-y-3 items-center md:space-y-0 md:space-x-3 md:flex-row`}
        >
          {product.variants.length > 1 && (
            <IonSelect
              className='flex w-full text-sm font-semibold text-green-600 border border-green-600 rounded-md shadow-md focus:ring-2 ring-green-600 h-11 line-clamp-1 border-opacity-30'
              value={currentVariantIndex}
              placeholder='Options'
              interface='action-sheet'
              onIonChange={(v: any) => {
                setCurrentVariantIndex(v?.detail?.value || 0);
              }}
            >
              {product.variants.map((variant, index) => {
                return (
                  <IonSelectOption
                    key={index}
                    value={index}
                    className='flex items-center divide-x-2'
                  >
                    {variant.title}, (${(variant.salePrice ? variant.salePrice : variant.price).toFixed(2)})

                    {product.isPreOrder && variant.preOrderDate &&
                      ` - ${dayjs(variant.preOrderDate.split('T')[0]).format('MM/DD/YYYY')}`
                    }
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}

          {/* Buy Now and Subscribe Buttons  */}
          <div className='row flex flex-col w-full filler items-center md:flex-row md:space-x-2'>

            {/* Dealing with each possible logic branch for the add/view buttons was getting complicated. Moved this into a function that should be more Manageable. */}
            {selectedVariant.inventory > 0 && (
              handleAddOrViewButton()
            )}

            {(!product.inMarket || selectedVariant.inventory === 0) && (
              <MWButton
                title='Watch Product'
                onClick={localAddToCart}
                bg-gray-200
                className='justify-center w-full px-4 text-white bg-gray-600 rounded-lg h-11'
              >
                <div className='flex-col'>
                  <div className='text-xs opacity-50 leading-none'>
                    Not available
                  </div>
                  <div className='text-base leading-none'>+ Watch List</div>
                </div>
              </MWButton>
            )}

            {!userState.isGuest && product.variants.find((v) => v.isSubscribed) &&
              !props.hideDetails && (
                <MWButton
                  title='Change subscription status'
                  onClick={() => presentSubscriptionModal()}
                  className='justify-center w-full px-4 text-sm font-bold text-black bg-gray-200 rounded-lg h-11'
                >
                  Subscribed
                  <IonIcon icon={checkmark}/>
                </MWButton>
              )}

            {!userState.isGuest && !product.variants.find((v) => v.isSubscribed) &&
              !props.hideDetails && (
                <MWButton
                  title='Subscribe to this Product'
                  onClick={() => subscribe()}
                  className='justify-center w-full px-4 text-sm font-bold text-green-800 bg-green-200 rounded-lg h-11'
                >
                  Subscribe <IonIcon className='ml-1' icon={reload}/>
                </MWButton>
              )}
          </div>
        </div>
      </div>
      {!props.hideDetails && (
        <div className='py-2 px-4'>
          <p className='text-gray-400 bg-transparent text-xs text-center'>
            {(product.previousOrderCount || 0) > 0 && (
              <span>
                You've ordered this {product.previousOrderCount}{' '}
                {product.previousOrderCount > 1 ? 'times' : 'time'}
              </span>
            )}
            {!product.previousOrderCount &&
              !product.previousVendorOrderCount && (
                <span>You've never ordered from this vendor before.</span>
              )}
            {!product.previousOrderCount &&
              product.previousVendorOrderCount !== 0 && (
                <span>
                  You've never ordered this product, but you've ordered{' '}
                  {product.previousVendorOrderCount} time(s) from this vendor.
                </span>
              )}
          </p>
        </div>
      )}
    </>
  );
};

export default ProductBuyBar;
