import Product from '../Product/ProductClass';
import md5 from 'md5';
import {CartItemType} from './types';

export default class CartItem {
  id?: string;
  quantity: number;
  subscription_interval?: number;
  watching: boolean;
  isPublished?: boolean;
  vendor: {
    id: number;
    name: string;
  };
  product: {
    id: number;
    images?: Array<string>;
    title: string;
    variant: {
      id: number;
      inventory: number;
      salePrice: number;
      price: number;
      title: string;
      preOrderDate?: string;
    };
  };

  constructor(starter: CartItemType) {
    this.id = starter.id;
    this.product = starter.product;
    this.vendor = starter.vendor;
    this.quantity = starter.quantity;
    this.watching = starter.watching;
    this.isPublished = starter.isPublished;
  }

  get hash(): string {
    return md5(
      `${this.id}${this.quantity}${this.product.id}${this.product.variant.id}}`
    );
  }

  get toProduct(): Product {
    return new Product({
      vendor: this.vendor,
      title: this.product.title,
      variants: [],
      images: this.product.images || [],
    });
  }
}
