import {IonBadge, IonIcon, IonLabel} from '@ionic/react';
import {
  cartOutline,
  cart,
  menu,
  flash,
  flashOutline,
  menuOutline, giftOutline,
} from 'ionicons/icons';
import {useEffect, useState} from 'react';
import CartItems from '../Cart/CartItems';
import FavoritesList from '../Favorites/FavoritesList';
import MoreLinks from '../More/MoreView';
import {CartStore} from '../Cart/CartStore';
import CartItem from '../Cart/CartItemClass';
import {UserStore} from '../User/UserStore';
import {useStoreState} from 'pullstate';
import IFrameModalController from "../../Components/IframeModal/IframeModalController";

const classShortcut = {
  fakeTab:
    'h-14 flex flex-col relative items-center justify-center transition-all duration-200 ease-in-out',
};

const DesktopAside = () => {
  const [activeTab, setActiveTab] = useState('cart');
  const cartState = CartStore.useState((s) => s);
  const [firstMount, setFirstMount] = useState(false);
  const userState = useStoreState(UserStore);
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);

  const isButtonDisabled = () => {
    let isDisabled: boolean;

    // If the userState doesn't have a property, return true;
    if (!userState.hasOwnProperty('isGuest')) {
      isDisabled = true;
    } else {
      isDisabled = !!userState.isGuest;
    }

    return isDisabled;
  }

  useEffect(() => {
    if (!firstMount) {
      window.addEventListener('friendsEvent', (e) => {
        if (e.type === 'friendsEvent') {
          setActiveTab('friends');
        }
      }, false);
    }

    setFirstMount(true);
    return () => window.removeEventListener('friendsEvent', () => {
      return true;
    });
  }, [firstMount])

  useEffect(() => {
    setActiveTab('cart');
  }, []);

  // Using a fake TabBar because ionics TabBarButton dues not work with onClick or

  return (
    <aside
      className='fixed top-0 bottom-0 right-0 z-50 hidden border-l border-gray-600 border-opacity-10 lg:flex lg:flex-col w-80'>
      <nav className='grid items-end grid-flow-col text-white bg-green-600 text-xl h-50'>
        {/*
          Cart
        */}
        <button
          className={`
            ${classShortcut.fakeTab}
            ${activeTab === 'cart' ? 'text-2xl' : 'text-xl'}
          `}
          onClick={() => {
            setActiveTab('cart');
          }}
        >
          <IonIcon
            className=''
            icon={activeTab === 'cart' ? cart : cartOutline}
          />
          <IonLabel className='mt-1 text-xs'>Cart</IonLabel>
          {cartState.items.length > 0 && (
            <IonBadge className='absolute top-0 right-0 text-white bg-red-500'>
              {cartState.items
                .map((cartItem: CartItem) => cartItem.quantity || 0)
                .reduce((a, b) => (a || 0) + b || 0)}
            </IonBadge>
          )}
        </button>


        {/* GiftCard */}
        <button
          className={`
            ${classShortcut.fakeTab}
          `}
          onClick={() => {
            setShowGiftCardModal(true);
          }}
        >
          <IonIcon
            className=''
            icon={giftOutline}
            slot='start'
          />
          <IonLabel className='mt-1 text-xs'>Gift Cards</IonLabel>
        </button>
        <IFrameModalController
          onDismiss={() => {
            setShowGiftCardModal(false);
          }}
          title='Gift Cards'
          url='https://portal.marketwagon.com/shop/gift-cards'
          open={showGiftCardModal}
        />

        {/*
          Favorites
        */}
        <button
          className={`
            ${classShortcut.fakeTab}
            ${isButtonDisabled() ? 'text-disabled font-medium opacity-50 cursor-not-allowed' : ''}
            ${!userState.isGuest && activeTab === 'favorites' ? 'text-2xl' : ''}
          `}
          onClick={() => {
            setActiveTab('favorites');
          }}
          disabled={!userState.hasOwnProperty('isGuest') ? true : userState.isGuest}
        >
          <IonIcon icon={activeTab === 'favorites' ? flash : flashOutline}/>
          <IonLabel className='mt-1 text-xs'>Quick Shop</IonLabel>
        </button>

        {/*
          More
        */}
        <button
          className={`
            ${classShortcut.fakeTab}
            ${!userState.hasOwnProperty('isGuest') ? 'text-disabled font-medium opacity-50 cursor-not-allowed' : ''}
            ${activeTab === 'more' ? 'text-2xl' : 'text-xl'}
          `}
          onClick={() => {
            setActiveTab('more');
          }}
          disabled={!userState.hasOwnProperty('isGuest')}
        >
          <IonIcon icon={activeTab === 'more' ? menu : menuOutline}/>
          <IonLabel className='mt-1 text-xs'>More</IonLabel>
        </button>
      </nav>

      {/* Main More List Items. */}
      <main className='h-full overflow-y-auto bg-gray-50'>
        {activeTab === 'cart' &&
          <>
            {/* <RecommendedBuys /> */}
            <CartItems className='p-2' itemClass='' item={undefined}/>
          </>
        }

        {activeTab === 'favorites' && <FavoritesList buttonType='icon'/>}

        {activeTab === 'more' && (
          <div className='pt-6'>
            <MoreLinks/>
          </div>
        )}

      </main>
    </aside>
  );
};

export default DesktopAside;
