import {VendorItemType} from '../Vendor/types';
import Vendor from '../Vendor/VendorClass';
import {VariantType} from './types';

export class Variant {
  id: number;
  title: string;
  inventory: number;
  price: number;
  previousPrice: number;
  salePrice: number;
  isSubscribed?: boolean;
  inStore: boolean;
  preOrderDate: string;

  constructor(starter: VariantType) {
    this.id = starter.id;
    this.title = starter.title;
    this.inventory = starter.inventory;
    this.price = starter.price;
    this.previousPrice = starter.previousPrice;
    this.isSubscribed = starter.isSubscribed;
    this.salePrice = starter.salePrice;
    this.inStore = starter.inStore;
    this.preOrderDate = starter.preOrderDate;
  }
}

type MarketType = { id: string; name: string };

export type ProductType = {
  id?: string;
  slug?: string;
  created?: string | Date;
  previousOrderCount?: number;
  previousVendorOrderCount?: number;
  isFavorite?: boolean;
  tags?: string;
  badgeType?: string;
  preOrderDate?: string;
  ratingCount?: number;
  avgRating?: number;
  ribbonText?: string;
  title?: string;
  images?: Array<string>;
  description?: string;
  variants?: Array<VariantType>;
  vendor?: VendorItemType;
  inMarket?: boolean;
  availableMarkets?: Array<MarketType>;
  isPreOrder?: boolean;
};

class Product {
  id?: number;
  slug?: string;
  created: Date;
  previousOrderCount: number = 0;
  previousVendorOrderCount: number = 0;
  isFavorite: boolean = false;
  tags: string = '';
  badgeType: string;
  preOrderDate?: Date;
  ratingCount: number = 0;
  avgRating: number = 0;
  ribbonText?: string;
  title: string;
  images: Array<string> = [];
  description: string;
  variants: Array<VariantType> = [];
  vendor?: Vendor;
  inMarket: boolean = true;
  availableMarkets?: Array<MarketType> = [];
  badge: number = 0;
  isPreOrder: boolean = false;
  originCountry?: string;
  originState?: string;
  originCounty?: string;
  newDescription?: string;
  processor?: string;

  constructor(starter: ProductType | any) {
    this.id = starter.id;
    this.slug = starter.slug;
    this.title = starter.title || 'Title Missing';
    this.created = starter.created ? new Date(starter.created) : new Date();
    // Previous Order Counts
    this.previousOrderCount = starter.previousOrderCount || 0;
    this.previousVendorOrderCount = starter.previousVendorOrderCount || 0;
    // Is product Favorite?
    this.isFavorite = !!starter.isFavorite;
    // Setup tags
    this.tags = starter.tags || '';
    // Set the Pre Order Date
    this.preOrderDate = starter.preOrderDate
      ? new Date(starter.preOrderDate)
      : undefined;
    // Set Impages
    this.images = starter.images instanceof Array ? starter.images : [];
    // Set Description
    this.description = starter.description || undefined;
    // Set Variants
    this.variants =
      starter.variants instanceof Array
        ? starter.variants.map((v: VariantType) => new Variant(v))
        : [];
    // Set Badge Type -- "Beyond Local","Wine",...
    this.badgeType = starter.badgeType;
    this.badge = starter.badge;
    // Set Vendor
    this.vendor = starter.vendor ? new Vendor(starter.vendor) : undefined;
    // Set Ratings
    this.avgRating = starter.avgRating || 0;
    this.ratingCount = starter.ratingCount || 0;
    this.ribbonText = starter.ribbonText;
    // Set Availability
    // TODO: setInMarket when its available from the API
    this.inMarket = starter?.inMarket === true ? true : true;
    this.availableMarkets = starter.availableMarkets
      ? starter.availableMarkets
      : [];
    this.isPreOrder = starter.isPreOrder;
    this.originCountry = starter.originCountry;
    this.originState = starter.originState;
    this.originCounty = starter.originCounty;
    this.originCounty = starter.originCounty;
    this.newDescription = starter.newDescription;
    this.processor = starter.processor;
  }

  get displayPrice(): string {
    if (this.variants.length) {
      return this.variants[0].salePrice ? parseFloat(`${this.variants[0].salePrice}`).toFixed(2) : parseFloat(`${this.variants[0].price}`).toFixed(2);
    }
    return '';
  }
}

export default Product;
