import {IonIcon, IonItem, IonList, useIonRouter} from '@ionic/react';

import {
  mailOutline,
  readerOutline,
  rocketOutline,
  fingerPrintOutline,
  helpBuoyOutline,
  thumbsUpOutline,
  idCardOutline,
  newspaperOutline, giftOutline
} from 'ionicons/icons';
import {useEffect, useState} from "react";
import IFrameModalController from "../../Components/IframeModal/IframeModalController";

const ContentFooter = ({...props}: any) => {
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showBecomeVendorModal, setShowBecomeVendorModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showWagonPassModal, setShowWagonPassModal] = useState(false);
  let path: string | null = String(window.location.pathname);
  const [hasMounted, setHasMounted] = useState(false);
  const router = useIonRouter();

  useEffect(() => {
    if (!hasMounted) {
      if (path === '/join') {
        setShowBecomeVendorModal(true);
      }

      if (path === '/gift-card') {
        setShowGiftCardModal(true);
      }

      if (path === '/wagon-pass') {
        setShowWagonPassModal(true);
      }

      setHasMounted(true);
    }
  }, [hasMounted, path]);

  return (
    <section className={`footer ${props?.className ?? null}`} aria-label='More about Market Wagon'>
      <main className='py-6 text-sm text-white bg-green-800 hidden md:block'>
        <div className='grid max-w-screen-md grid-cols-1 gap-4 px-4 mx-auto md:gap-10 md:px-0 md:grid-cols-2 '>
          <IonList className='bg-transparent'>
            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              onClick={() => {
                setShowGiftCardModal(true);
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon slot='start' icon={giftOutline}/>
              Gift Cards
            </IonItem>

            <IFrameModalController
              onDismiss={() => {
                setShowGiftCardModal(false);
              }}
              title='Gift Cards'
              url='https://portal.marketwagon.com/shop/gift-cards'
              open={showGiftCardModal}
            />

            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              routerLink='/help'
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon slot='start' icon={mailOutline}/>
              Help Center
            </IonItem>
            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              onClick={() => {
                setShowTermsModal(true);
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon slot='start' icon={readerOutline}/>
              Terms &amp; Conditions
            </IonItem>

            <IFrameModalController
              onDismiss={() => {
                setShowTermsModal(false);
              }}
              title='Terms &amp; Conditions'
              url='https://marketwagon.com/pages/conditions-of-use'
              open={showTermsModal}
            />

            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              onClick={() => {
                router.push('/about');
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon slot='start' icon={thumbsUpOutline}/>
              About Us
            </IonItem>

            {/* Begin Blog */}
            <IonItem
              button color='transparent'
              className='text-sm text-white'
              onClick={() => {
                router.push('/blog');
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon slot='start' icon={newspaperOutline}/>
              Blog
            </IonItem>
            {/* End Blog */}
          </IonList>

          <IonList className='bg-transparent'>
            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              onClick={() => {
                window
                  ?.open(
                    'https://dairy.marketwagon.com/',
                    '_blank',
                    'noopener nofollow'
                  )
                  ?.focus();
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon slot='start' icon={rocketOutline}/>
              Become a Vendor
            </IonItem>

            <IFrameModalController
              onDismiss={() => {
                path = null;
                setShowBecomeVendorModal(false);
              }}
              title='Become a Vendor'
              url='https://marketwagon.com/pages/apply-to-become-a-vendor.php'
              open={showBecomeVendorModal}
            />

            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              onClick={() => {
                router.push('/faq');
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon icon={helpBuoyOutline} slot='start'/>
              Common Questions
            </IonItem>

            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              onClick={() => {
                setShowPrivacyModal(true);
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon icon={fingerPrintOutline} slot='start'/>
              Privacy
            </IonItem>

            <IFrameModalController
              onDismiss={() => {
                setShowPrivacyModal(false);
              }}
              title='Privacy'
              url='https://marketwagon.com/privacy.php'
              open={showPrivacyModal}
            />

            <IonItem
              button
              color='transparent'
              className='text-sm text-white'
              onClick={() => {
                setShowWagonPassModal(true);
              }}
              style={{'--ion-color-contrast': 'white'}}
            >
              <IonIcon icon={idCardOutline} slot='start'/>
              Wagon Pass
            </IonItem>

            <IFrameModalController
              onDismiss={() => {
                setShowWagonPassModal(false);
              }}
              title='Wagon Pass'
              url={process.env.REACT_APP_WAGON_PASS_URL ?? 'https://marketwagon.com/wagon-pass'}
              open={showWagonPassModal}
            />
          </IonList>
        </div>

        <div className='my-6 text-xs text-center'>
          {' '}
          &copy; 2017-{new Date().getFullYear()} Market Wagon, Inc.
        </div>
      </main>
    </section>
  );
};

export default ContentFooter;
